<template>
    <b-container class="card-body p-0">
      <b-row>
        <b-col>
          <div class="mb-2">
            {{item.name}}
          </div>
        </b-col>
      </b-row>
    </b-container>
</template>

<script>
export default {
  name: 'ControlStatus',
  props: ['item', 'username']
}
</script>

<style>
</style>
