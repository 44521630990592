<template>
<div class="my-4">
  <b-container>
    <b-row class="text-center mb-4" align-h="center" v-if="!permissions.read">
      <b-col cols="1">
        <h3>You do not have permissions for this page</h3>
      </b-col>
    </b-row>
    <b-row class="text-center mb-4" align-h="center" v-if="loading && permissions.read">
      <b-col cols="1">
        <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
      </b-col>
    </b-row>
    <b-row class="mb-4" v-if="!loading && permissions.read">
      <b-col>
        <b-card class="mb-3">
          <b-button v-if="permissions.add" variant="outline-primary" :to="{ name: 'FormNew', params: {model: 'controlstatus' }}">add status</b-button>
        </b-card>
        <b-card class="mb-3" v-for="row in showRows" :key="row.id">
          <control-status
            :item="row"
            :username="user.username"
          />
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import ac from '@/libs/accesscontrol'

import ControlStatus from '@/components/ControlStatus.vue'

export default {
  name: 'Statuses',
  components: {
    ControlStatus
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    document.title = "Control Statuses"
    this.$stat.log({ page: 'control statuses', action: 'open control statuses' })
    this.permissions.read = ac.can(this.user.acgroups).readAny('controlstatus').granted
    this.permissions.add = ac.can(this.user.acgroups).createAny('controlstatus').granted
    if (this.permissions.read) {
      this.load()
    }
  },
  data () {
    return {
      permissions: {
        add: false,
        read: false
      },
      filter: '',
      loading: true,
      rows: [],
      showRows: []
    }
  },
  methods: {
    load: async function () {
      this.loading = true
      try {
        let apiName = 'cosmos'
        let path = '/standard/controlstatus'
        this.rows = await this.$Amplify.API.get(apiName, path)
        this.showRows = this.rows
        this.loading = false
      } catch (e) {
        this.$logger.wrn('saved ERROR: ', e)
      }
    }
  }
}
</script>

<style>
</style>
